<template>
  <AppLayout>
    <router-view v-slot="{ Component }">
      <transition appear name="slide-fade" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </AppLayout>
</template>

<script>
import AppLayout from "./layouts";
import "./styles/main.scss";
import "primeflex/primeflex.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primevue/resources/themes/saga-green/theme.css";
import Carousel from "primevue/components/carousel/Carousel";
export default {
  components: { AppLayout },
  created() {
    if (this.breakpoint.smAndDown) {
      Carousel.methods.onTouchMove = () => {};
    }
  },
  mounted() {
    const hubspotTracking = document.createElement("script");
    hubspotTracking.setAttribute("src", "//js.hs-scripts.com/3962365.js");
    document.head.appendChild(hubspotTracking);
  }
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
