<template>
  <div class="p-grid p-ai-center p-jc-center container">
    <div class="p-col" style="flex: 0 1 calc(100% - 20px)">
      <ProgressBar mode="indeterminate" style="height: 0.25em" />
    </div>
  </div>
</template>
<script>
export default {};
</script>
