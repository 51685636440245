<template>
  <div class="layout-footer p-d-flex p-flex-column p-jc-stretch">
    <div class="p-grid contact-bar p-mb-2">
      <div class="p-col-12">
        <div class="p-grid container">
          <div class="p-col-12 p-md-10">
            <p
              class="p-my-0"
              :style="{
                textTransform: 'titlecase',
                color: contactTextColor
              }"
            >
              {{ contactText }}
            </p>
          </div>
          <div class="p-col p-d-flex p-jc-end p-ai-center">
            <a href="/contact" @click="navigateTo({ name: 'contact.index' })">
              <Button
                label="Contact Us"
                class="contact-us-button"
                :style="{ width: breakpoint.smAndDown ? '100%' : 'auto' }"
              ></Button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="p-grid content">
      <div class="p-col-12">
        <div class="p-grid container">
          <div class="p-col-12 p-md-10">
            <h1 style="max-width: 700px; margin-top: 0px">{{ footerTag }}</h1>
          </div>
          <div class="p-col p-d-flex p-jc-end p-ai-center">
            <a href="/new-project" @click="navigateTo({ name: 'new-project' })">
              <Button
                label="Start a Project"
                class="p-button-outlined"
                :style="{
                  width: breakpoint.smAndDown ? '100%' : 'auto',
                  color: '#fff'
                }"
              ></Button>
            </a>
          </div>
          <div class="p-col-12">
            <div class="footer-content-divider" />
          </div>
          <div class="p-col-12">
            <div class="p-grid">
              <div class="p-col-12 p-md-6 p-d-flex p-jc-start p-ai-center">
                <p class="p-text-bold" style="max-width: 182px">
                  Made in New York & the Caribbean
                </p>
              </div>
              <div
                :class="{
                  'p-col-12 p-md-6': true,
                  'p-d-flex p-jc-end p-ai-center': breakpoint.mdAndUp
                }"
              >
                <router-link
                  v-for="(item, index) in menu"
                  class="menu-link p-ml-3 p-mr-3"
                  :key="`footer-menu-item-${index}`"
                  :to="item.route"
                  >{{ item.title }}</router-link
                >
              </div>
            </div>
            <div
              class="p-col-12 p-d-flex p-jc-start p-ai-center contact-address p-d-flex p-flex-column p-pl-0"
            >
              <p
                style="max-width: 182px; margin-bottom: 4px; line-height: 1.5rem"
              >
                1412 Broadway, New York, New York 10018
              </p>
              <div class="p-grid">
                <a
                  class="menu-link menu-link-contact p-col-12"
                  :href="`tel:${contactPhone}`"
                  >{{ contactPhone }}‬</a
                >
                <a
                  class="menu-link menu-link-contact p-col-12"
                  :href="`mailto:${contactEmail}`"
                  >{{ contactEmail }}</a
                >
              </div>
            </div>
          </div>
          <div class="p-col-12 p-mt-3">
            <div class="footer-content-divider" />
          </div>
          <div class="p-col-12">
            <div class="p-grid">
              <div class="p-col-12 p-md-8 p-d-flex p-jc-start p-ai-center">
                &copy; NITM Inc.
              </div>
              <div class="p-col-12 p-md-4 p-d-flex p-jc-end p-ai-center">
                <router-link class="menu-link p-ml-3 p-mr-3" to="#"
                  >Privacy</router-link
                >
                <router-link class="menu-link p-ml-3 p-mr-3" to="#"
                  >Terms</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
export default {
  name: "AppFooter",
  setup(props) {
    return {
      menu: inject("menu"),
      contactPhone: "+1 (347) 241-2690",
      contactEmail: "hello@nitm.co",
      contactTextColor: "#fff",
      contactText:
        "Modern business transformation requires innovation focused on the overall customer experience. we're here to help.",
      footerTag: props.footerTag || "Let’s make something new together"
    };
  }
};
</script>

<style lang="scss">
@import "../styles/variables.scss";

.layout-footer {
  width: 100%;
  height: 100%;
  > .p-grid {
    padding-top: 20px;
    padding-bottom: 20px;

    .menu-link {
      line-height: 0.75rem !important;
      font-size: 0.75rem !important;

      @media (max-width: 768px) {
        line-height: 2rem !important;
        font-size: 1rem !important;
        margin-left: 0px !important;
        display: block;
        width: 100%;
      }
      &:last-child {
        margin-right: 0px !important;
      }

      &.menu-link-contact {
        text-transform: lowercase !important;
        font-weight: normal;
      }
    }

    &.contact-bar {
      padding: 120px 0px 20px;
      flex: 1 1 85px;
      background-color: $successColor;

      .p-button.contact-us-button {
        color: $successColor !important;
        background: #fff;
        background-color: #fff;
        border: none;
      }

      @media (max-width: 768px) {
        padding: 40px 20px;
      }
    }

    &.content {
      color: #fff;
      flex: 1 1 100%;
      background-color: $secondaryColor;

      .contact-address {
        max-width: 186px;
        margin-left: 0px;
      }
    }

    .footer-content-divider {
      height: 1px;
      width: 100%;
      margin: 10px 0px;
      background-color: $successColor;
    }
  }
}
</style>
