<template>
  <Loading v-if="isBuilding" style="height: 100vh"></Loading>
  <div
    v-else
    id="fullpage"
    ref="fullpage"
    :style="{
      opacity: isReady ? 1 : 0
    }"
    :class="{
      ...{
        'no-auto-scroll': options.autoScrolling === false
      },
      ...classes
    }"
  >
    <slot></slot>
    <div class="section footer">
      <AppFooter />
    </div>
  </div>
</template>

<script>
const fullpage = require("fullpage.js/dist/fullpage.extensions.min");
import * as constants from "vue-fullpage.js/src/constants.js";
function camelToKebab(string) {
  return string.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}
import AppFooter from "./AppFooter.vue";

export default {
  name: "LayoutFullPage",
  props: {
    customOptions: {
      type: Object,
      required: false,
      default: () => {}
    },
    classes: {
      type: Object,
      required: false,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    skipInit: {
      type: Boolean,
      default: true
    },
    disableNavigation: {
      type: Boolean,
      default: true
    },
    autoScrolling: {
      type: Boolean,
      default: true
    },
    page: {
      type: [Number, String],
      default: null
    },
    menuSelector: {
      type: String,
      default: "#main-menu"
    }
  },
  components: { AppFooter },
  data() {
    return {
      isReady: false,
      options: {
        menu: this.menuSelector,
        navigationPosition: "right",
        licenseKey: process.env.VUE_APP_FULLPAGE_TOKEN,
        scrollOverflow: false,
        easingcss3: "ease-in-out",
        autoScrolling: !this.autoScrolling ? false : this.breakpoint.mdAndUp,
        navigation: this.disableNavigation ? false : this.breakpoint.mdAndUp,
        loopHorizontal: false,
        fitToSection: !this.autoScrolling ? false : this.breakpoint.mdAndUp,
        afterRender: () => {
          this.setReady();
        },
        afterLoad: (origin, destination) => {
          console.log("Emitting: Page section changed to", destination.index);
          this.$bus.emit("update:current-page-section", destination.index);
        }
      },
      events: constants.EVENTS.reduce((eventsHandlers, event) => {
        return {
          ...eventsHandlers,

          [event]: (...args) => {
            this.emitEvent(event, args);
          }
        };
      }, {}),
      fullpageApi: undefined
    };
  },
  watch: {
    isLoading: function(value) {
      this.$log.info("Checking isLoading value", value);
      if (!value) {
        this.$nextTick(() => {
          this.build();
        });
      }
    },
    page: function(value) {
      if (value) {
        this.fullpageApi.moveTo(value, 0);
      }
    },
    options: {
      deep: true,
      handler() {
        this.$log.info("Building due to options");
        this.build();
      }
    }
  },
  computed: {
    fpUtils() {
      return window.fp_utils;
    },
    isBuilding() {
      return this.isLoading && !(this.fullpageApi instanceof Object);
    }
  },
  mounted() {
    // window.setTimeout(() => {
    //   this.init();
    // }, 5);
    // this.$router.beforeEach((to, from, next) => {
    //   this.build();
    //   next();
    // });
  },
  beforeUnmount() {
    this.$log.info("Unmounting");
    if (typeof this.fullpageApi !== "undefined") {
      this.destroy();
    }
  },
  methods: {
    build() {
      const slideSelector =
        this.options.autoScrolling === false
          ? "#non-existent-slide" + Date.now()
          : this.options.slideSelector || ".slide";
      const sectionSelector =
        this.options.autoScrolling === false
          ? "#non-existent-section" + Date.now()
          : this.options.sectionSelector || ".section";
      const activeSectionNode = document.querySelector(
        sectionSelector + ".active"
      );
      let activeSlide,
        activeSlideIndex = -1,
        activeSectionIndex = -1;
      if (activeSectionNode instanceof HTMLElement) {
        activeSectionIndex = this.fpUtils.index(activeSectionNode);
        activeSlide = document.querySelector(
          sectionSelector + ".active " + slideSelector + ".active"
        );
        activeSlideIndex =
          activeSlide instanceof HTMLElement
            ? this.fpUtils.index(activeSlide)
            : -1;
      }
      this.$log.info("Destroying due to build");

      this.destroy();

      if (activeSectionIndex > -1) {
        this.fpUtils.addClass(
          document.querySelectorAll(sectionSelector)[activeSectionIndex],
          "active"
        );
      }

      if (activeSlideIndex > -1) {
        this.fpUtils.addClass(activeSlide, "active");
      }

      this.$log.info("Initing due to build");
      setTimeout(this.init, 150);
      // this.init();
    },
    rebuild() {
      this.$log.info("Attempting to rebuild", this.name);
      if (
        typeof this.fullpageApi !== "undefined" &&
        typeof this.fullpageApi.reBuild !== "undefined"
      ) {
        this.$log.info("Rebuilding", this.name);
        this.fullpageApi.reBuild();
      }
    },
    destroy() {
      this.$log.info("Attempting to destroy", this.name);
      if (
        typeof this.fullpageApi !== "undefined" &&
        typeof this.fullpageApi.destroy !== "undefined"
      ) {
        this.$log.info("Destroying", this.name);
        this.fullpageApi.destroy("all");
        this.isReady = false;
        this.fullpageApi = null;
      }
    },
    emitEvent(name, args) {
      // Emit event on Vue way
      this.$log.info("Emitting", name);
      this.$emit(camelToKebab(name), ...args);

      // Run event's handler with non Vue way
      if (Object.prototype.hasOwnProperty.call(this.options, name)) {
        this.options[name].apply(this, args);
      }
    },
    init() {
      this.$nextTick(() => {
        // eslint-disable-next-line
        if (this.fullpageApi instanceof fullpage) {
          this.$log.info("Rebuilding due to init");
          this.rebuild();
        } else if (window.fullpage === undefined) {
          this.$log.info("Initing fresh");
          this.fullpageApi = new fullpage(this.$refs.fullpage, {
            ...this.options,
            ...this.customOptions
          });
        } else {
          this.fullpageApi = new fullpage(this.$refs.fullpage, {
            ...this.options,
            ...this.customOptions
          });
        }
      });
    },
    setReady() {
      setTimeout(() => (this.isReady = true), 200);
    }
  }
};
</script>

<style lang="scss">
#fullpage {
  @media (max-width: 768px) {
    .section:first-of-type {
      .section-content:first-of-type {
        padding-top: 150px !important;
        @media (max-width: 768px) {
          padding-top: 75px !important;
          align-items: center;
        }
      }
    }
  }
  &.no-auto-scroll {
    .section,
    .fp-tableCell,
    .fp-scrollable,
    .fp-scroller {
      height: max-content !important;
    }
  }

  &.light-theme {
    .section-content {
      background-color: #fff;
    }
  }
}
#fp-nav.fp-right {
  right: 2vw;
  @media (min-width: 1200px) {
    right: 2vw;
  }
}
#fp-nav {
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
  z-index: 100;
}

#fp-nav {
  ul {
    li {
      list-style-type: none;
      text-align: center;
      a {
        display: block;
        width: 14px;
        height: 14px;
        margin: 7px auto;
        position: relative;
        border: solid 2px #000;
        border-radius: 50%;

        .fp-sr-only {
          display: none;
        }

        &.active {
          background-color: #000;
        }
      }
    }
  }
}

.section.footer {
  overflow: hidden;
}
</style>
