<template>
  <div
    class="section-content"
    :style="{
      minHeight: '100vh !important',
      backgroundImage: data.image ? `url('${data.image}')` : '',
      backgroundColor: data.background_color,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }"
  >
    <div
      class="p-grid container"
      :style="{
        width: '100%'
      }"
    >
      <div class="p-col-12 p-md-8">
        <h1
          :style="{
            color: data.text_color || 'inherit'
          }"
        >
          {{ data.title }}
        </h1>
        <p v-if="data.description" v-html="data.description"></p>
      </div>
      <div
        v-if="data.url || data.action"
        class="p-col-12 p-md-4 p-d-flex p-ai-center"
      >
        <a :href="data.url || false"
          ><Button
            class="p-px-6 p-py-4"
            :style="{
              backgroundColor: data.button_background_color || '',
              color: data.button_text_color || data.text_color || 'inherit'
            }"
            :label="data.url_text"
            :icon="data.link_icon"
            :icon-pos="data.link_icon_position"
            @click="emitAction(data.action)"
          />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import useSetsCurrentProject from "@/composables/SetsCurrentProject.vue";

export default {
  props: {
    data: { type: Object, default: () => {} },
    isHeroSlide: { type: Boolean, default: true }
  },
  emits: ["fullpage:move-to", "fullpage:move-up", "fullpage:move-down"],
  methods: {
    emitAction(action) {
      if (action) {
        console.log("emitting", action);
        this.$emit(action);
      }
    }
  },
  mounted() {
    const {
      updateProjectTextColor,
      updateProjectColorMode
    } = useSetsCurrentProject();

    this.$bus.on("update:current-page-section", section => {
      if (this.isHeroSlide && section == 0) {
        updateProjectTextColor(this.data, "dark");
        updateProjectColorMode(this.data, "light");
      }
    });

    setTimeout(() => {
      updateProjectTextColor(this.data, "dark");
      updateProjectColorMode(this.data, "light");
    }, 500);
  }
};
</script>
