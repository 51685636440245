<template>
  <div
    v-if="slides instanceof Array && parseInt(slides.length) > 0"
    class="section slides hidden-navigation"
  >
    <Carousel
      v-if="parseInt(slides.length) > 0"
      style="height: 100%"
      class="absolute-navigation hidden-navigation"
      :value="slides"
      :num-visible="slides.length > 0 ? 1 : 0"
      :num-scroll="1"
      :circular="slides.length > 1"
      :autoplay-interval="3000"
    >
      <template #item="{data}">
        <HeroSlide
          :is-hero-slide="isHeroSlide"
          :data="data.attributes"
          @fullpage:move-to="
            (location, index) => $emit('fullpage:move-to', location, index)
          "
          @fullpage:move-up="$emit('fullpage:move-up')"
          @fullpage:move-down="$emit('fullpage:move-down')"
        />
      </template>
    </Carousel>
  </div>
</template>
<script>
export default {
  props: {
    slides: { type: Array, default: () => [] },
    isHeroSlide: { type: Boolean, default: true }
  },
  emits: ["fullpage:move-to", "fullpage:move-up", "fullpage:move-down"]
};
</script>
